import styled from 'styled-components';
import { purple } from 'styles/colors';
import { fromLarge } from 'styleUtils/mediaQueries';

interface Props {
    size: string;
    border?: string;
}

export const Avatar = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
    position: relative;

    img,
    video {
        border-radius: 100%;
        object-fit: cover;
        object-position: top;
        font-family: 'object-fit: cover; object-position: top;';
        display: inline-block;

        ${({ size }): string =>
            size &&
            `
                min-height: ${size};
                min-width: ${size};
                max-width: ${size};
                max-height: ${size};`}
        ${({ border }): string => border && fromLarge`border: ${border} solid ${purple};`}
    }
`;
