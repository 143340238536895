import React from 'react';

interface Props {
    teams: Array<{ name: string }>;
}

export const FormatTeams: React.FC<Props> = (props) => {
    const teamNamesArray = props.teams.map((team) => {
        return team.name;
    });

    return <span>{teamNamesArray.join(' ')}</span>;
};
