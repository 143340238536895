import React from 'react';
import { mdiLinkedin, mdiPhone, mdiEmail, mdiWhatsapp } from '@mdi/js';

import { Wrapper } from './atoms/Wrapper';
import { IconLink } from '../IconLink';
import { existy } from 'utils/functional';

interface Props {
    meetUrl: string;
    phone: string;
    email: string;
}

export const IconLinkGrid: React.FC<Props> = ({ meetUrl, phone, email }) => (
    <Wrapper>
        {existy(phone) && <IconLink rel="noopener noreferrer" url={`https://wa.me/${phone}`} icon={mdiWhatsapp} />}
        {existy(phone) && <IconLink rel="noopener noreferrer" url={`tel:${phone}`} icon={mdiPhone} />}
        {existy(email) && <IconLink rel="noopener noreferrer" url={`mailto:${email}`} icon={mdiEmail} />}
        {existy(meetUrl) && <IconLink rel="noopener noreferrer" target="_blank" url={meetUrl} icon={mdiLinkedin} />}
    </Wrapper>
);

export default IconLinkGrid;
