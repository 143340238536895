import React from 'react';

import { FILE_BASE_URL } from 'variables';

interface Props {
    isStatic?: string;
    image: {
        alt?: string;
        imageUpdated: string;
        isAnimated: boolean;
        id: number;
        imagePath: string;
        webPPath: string;
        webMPath: string;
        mp4Path: string;
    };
}
export const WebImage: React.FC<Props> = ({ image, isStatic }) => {
    if (image === undefined || !image) {
        return (
            <picture>
                <source type="image/webp" srcSet="" />
                <source type="image/jpeg" srcSet="" />
                <img alt="notfound" src="" />
            </picture>
        );
    }

    const { isAnimated, alt } = image;
    let { imagePath, mp4Path, webMPath, webPPath } = image;

    if (!isStatic) {
        imagePath = FILE_BASE_URL + imagePath;
        mp4Path = FILE_BASE_URL + mp4Path;
        webMPath = FILE_BASE_URL + webMPath;
        webPPath = FILE_BASE_URL + webPPath;
    }

    if (isAnimated) {
        return (
            <video
                autoPlay
                loop
                muted
                playsInline
                // disableRemotePlayback is not recognized by TS so ignoring the next line for now.
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                disableRemotePlayback
            >
                <source src={webMPath} type="video/webm" />
                <source src={mp4Path} type="video/mp4" />
            </video>
        );
    }

    return (
        <picture>
            <source type="image/webp" srcSet={webPPath} />
            <source type="image/jpeg" srcSet={imagePath ? imagePath : webPPath} />
            <img alt={alt} src={imagePath ? imagePath : webPPath} />
        </picture>
    );
};
